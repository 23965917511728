// Metric event names
export enum MetricEvent {
  AI_TUTOR_CHAT_COMPLETION_FAIL = 'AI_TUTOR_CHAT_COMPLETION_FAIL',
  AI_TUTOR_CHAT_PROFANITY_PII_VIOLATION = 'AI_TUTOR_CHAT_PROFANITY_PII_VIOLATION',
  AI_TUTOR_CHAT_SAVE_FAIL = 'AI_TUTOR_CHAT_SAVE_FAIL',
  AI_TUTOR_CHAT_FETCH_FAIL = 'AI_TUTOR_CHAT_FETCH_FAIL',
  AI_TUTOR_FEEDBACK_SAVE_FAIL = 'AI_TUTOR_FEEDBACK_SAVE_FAIL',
  AI_TUTOR_UPDATE_SECTION_ACCESS_FAIL = 'AI_TUTOR_UPDATE_SECTION_ACCESS_FAIL',
  AI_TUTOR_UPDATE_USER_ACCESS_FAIL = 'AI_TUTOR_UPDATE_USER_ACCESS_FAIL',
  GOOGLE_BLOCKLY_GET_CODE_ERROR = 'BLOCKLY_GET_CODE_ERROR',
  CDO_BLOCKLY_GET_CODE_ERROR = 'CDO_BLOCKLY_GET_CODE_ERROR',
}
